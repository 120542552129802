/* ModalAnimations.css */
@keyframes zoom-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes flip-in {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: perspective(400px) rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-out {
  0% {
    transform: perspective(400px) rotateY(0);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

.modal-open {
  animation: zoom-in 0.6s ease forwards, flip-in 0.4s ease forwards; /* Zoom and flip-in effect for opening */
}

.modal-close {
  animation: zoom-out 0.6s ease forwards, flip-out 0.4s ease forwards; /* Zoom and flip-out effect for closing */
}
